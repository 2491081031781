<template>
  <div class="content-5-view" style="background: #F9F9F9;padding: 60px 10px;">
    <div class="content-5">
      <div>
        <h1 style="color: #353535; font-size: 36px;">蓝鲸读书汇，精彩天天见</h1>
      </div>
      <div class="content-list">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8">
            <div class="list-item">
              <img src="@/assets/images/pic-1.jpg" />
              <p>2021年5月</p>
              <p>河北安平店开业</p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="8">
            <div class="list-item">
              <img src="@/assets/images/pic-2.jpg" />
              <p>2021年6月</p>
              <p>北京海淀金源店开业</p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="8">
            <div class="list-item">
              <img src="@/assets/images/pic-3.jpg" />
              <p>2021年7月</p>
              <p>河南迪诺教育集团转向素质教育</p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="8">
            <div class="list-item">
              <img src="@/assets/images/pic-4.jpg" />
              <p>2021年8月</p>
              <p>银川亿生教育集团转向素质教育</p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="8">
            <div class="list-item">
              <img src="@/assets/images/pic-5.jpg" />
              <p>约读人平凡又特别的一天</p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="8">
            <div class="list-item">
              <img src="@/assets/images/pic-6.jpg" />
              <p>2019蓝鲸读书汇活动回顾</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="text-align: center; margin-top: 40px;">
        <button class="more-btn">了解更多</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Content5"
}
</script>

<style scoped>
  .content-5{
    margin: 0 auto;
    max-width: 1200px;
    text-align: center;
  }
  .list-item{
    margin-bottom: 20px;
  }
  .list-item img{
    width: 100%;
    height: 231px;
  }
  .list-item p{
    color: #565656;
    margin: 5px;
  }
  .more-btn{
    width: 200px;
    margin: 10px;
    border-radius: 20px;
    border: none;
    padding: 10px;
    background: #1563aa;
    color: white;
  }
</style>

<template>
  <div class="content-3-view" style="background: #F9F9F9;padding: 60px 10px;">
    <div class="content-3">
      <div>
        <h1 style="color: #353535; font-size: 36px;">出自北京师范大学儿童阅读与学习研究院的阅读体系</h1>
        <p style="color: #565656;">培养孩子的阅读习惯、阅读能力和学习能力，助力青少年成为"智商、情商、财商、逆商、德商"五商俱全的T型人才。</p>
      </div>
      <div class="content-list">
        <div @click="toArticleList(0)">
          <img src="@/assets/images/t1.jpg" />
          <h3>公益借阅</h3>
          <p>素质教育的核心所在</p>
        </div>
        <div @click="toArticleList(1)">
          <img src="@/assets/images/t2.jpg" />
          <h3>家庭书房</h3>
          <p>掌握人生的核心素养</p>
        </div>
        <div @click="toArticleList(2)">
          <img src="@/assets/images/t3.jpg" />
          <h3>蓝鲸读书会</h3>
          <p>知识内化的核心动力</p>
        </div>
        <div @click="toArticleList(3)">
          <img src="@/assets/images/t4.jpg" />
          <h3>家长学校</h3>
          <p>家庭幸福的活水源</p>
        </div>
        <div @click="toArticleList(4)">
          <img src="@/assets/images/t5.jpg" />
          <h3>专注力训练营</h3>
          <p>孩子成长的必备能力</p>
        </div>
        <div @click="toArticleList(5)">
          <img src="@/assets/images/t6.jpg" />
          <h3>学习力训练营</h3>
          <p>立于不败的神奇力量</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Content3",
  methods: {
    toArticleList(type){
      this.$router.push({path: '/list', query: {id: 2,type: type}});
    }
  }
}
</script>

<style scoped>
  .content-3{
    margin: 0 auto;
    max-width: 1200px;
    text-align: center;
  }
  .content-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .content-list > div {
    background: white;
    padding: 30px;
    margin: 30px;
    width: 22%;
    border-radius: 5px;
    border: 1px solid gainsboro;
  }
  .content-list > div img{
    width: 180px;
  }
  .content-list > div h3{
    color: #1563AA;
  }
  .content-list > div p{
    color: #565656;
    text-align: center;
  }
  .more-btn{
    width: 200px;
    margin: 10px;
    border-radius: 20px;
    border: none;
    padding: 10px;
    background: #1563aa;
    color: white;
  }
</style>
<template>
  <div class="content-1-view">
    <div class="content-1">
      <div>
        <div>
          <img src="@/assets/images/img_r6_c4.jpg" width="350px">
        </div>
        <div style="padding: 20px 40px;">
          <h1 style="color:#353535; font-size: 36px;">走 进 蓝 鲸</h1>
          <p style="color: #565656;line-height: 30px;">蓝鲸读书汇是由北京师范大学儿童阅读与学习研究院和蓝鲸读书汇教育科技（北京）有限公司共同推出的公益性读书组织，是专为地方政府落实全民阅读量身定制的系统性解决方案。全国各地设立读书场所，专为学生、学生家长和学校教师提供阅读服务，倡导舒适性、个性化、全方位的阅读体验。读者可以在读书汇免费借书、免费看书、免费参加读书汇举办的阅读活动。</p>
          <button style="padding: 10px 40px; border: none; background: #1563AA; color: white; border-radius: 20px;" @click="toDetail">了解更多</button>
          <div class="data-view">
            <div>
              <h1>120<p>个春秋</p></h1>
              <p>庆北师大建校120周年</p>
            </div>
            <div>
              <h1>120<p>个城市</p></h1>
              <p>在120个城市设立读书汇</p>
            </div>
            <div>
              <h1>5000<p>个门店</p></h1>
              <p>全国共设立5000个阅读门店</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Content1",
  methods: {
    toDetail(){
      this.$router.push({path: '/list', query: {id: 1}});
    }
  }
}
</script>

<style scoped>
.content-1-view{
  padding: 30px 10px 10px 10px;
}
.content-1{
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0 auto;
}
.content-1 > div:first-child{
  display: flex;
}
.data-view{
  display: flex;
  justify-content: space-around;
}
.data-view h1{
  color: #1563AA;
  font-size: 72px;
  margin-bottom: 0;
  display: flex;
}
.data-view h1 > p{
  font-size: 16px;
  color: #1563AA;
  margin-top: 40px;
  margin-left: 5px;
}
.data-view p{
  color: #565656;
  margin-top: -20px;
}
</style>

<template>
  <div class="content-2-view" :style="{'backgroundImage':'url('+bgImg+')'}">
    <div class="content-2">
      <div>
        <h1 style="color: #353535; font-size: 36px;">助力全民阅读&nbsp;&nbsp;提升素质教育</h1>
        <p style="color: #565656">全国网络竭诚为您服务</p>
      </div>
      <div style="padding: 40px;">
        <input type="text" v-model="keyword" placeholder="请输入城市名称" class="search-input" />
        <button class="search-btn" @click="getLibrary">搜索</button>
        <span style="color: #565656; margin-left: 40px;">截止到{{nowMonth}}，全国共计{{count}}门店</span>
      </div>
      <div class="city-list">
        <button v-for="(item,index) in list" :key="index">{{ item.name }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {libraryList} from "@/api/common";
import {getYearMonth} from "@/utils";

export default {
  name: "Content2",
  data() {
    return {
      bgImg: require('@/assets/images/bg.jpg'),
      keyword: "",
      list: [],
      count: 0,
      nowMonth: getYearMonth(new Date().getTime()),
    }
  },
  created() {
    this.getLibrary(1)
  },
  methods: {
    async getLibrary(tag){
      let result = await libraryList({"name": this.keyword})
      if(result.code === 1){
        this.list = result.data
        if(tag === 1){
          this.count = result.data.length
        }
      }
    },
    toLibraryMap(){
      this.$router.push({path: '/list', query: {id: 3}});
    }
  }
}
</script>

<style scoped>
  .content-2-view{
    padding: 60px 10px;
  }
  .content-2{
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  .search-input{
    padding: 12px 20px;
    border: 1px solid gainsboro;
    border-radius: 20px;
    width: 280px;
  }
  .search-btn{
    padding: 10px 30px;
    margin-left: 40px;
    background: #1563AA;
    border: none;
    color: white;
    border-radius: 20px;
  }
  .city-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .city-list button, .more-btn{
    width: 200px;
    margin: 10px;
    border-radius: 20px;
    border: none;
    padding: 10px;
    background: #1563aa;
    color: white;
  }
</style>
<template>
  <div class="content-4-view" style="padding: 60px 10px;">
    <div class="content-4">
      <div>
        <h1 style="color: #353535;font-size: 36px;">北师大儿研院核心研究成员</h1>
        <p style="color: #565656;">以"帮助青少年养成阅读习惯，提升学习力和内驱力"为宗旨，致力于让每一位孩子学会阅读、爱上阅读、爱上学习。</p>
      </div>
      <div class="content-list">
          <img src="@/assets/images/img_r10_c2.jpg" />
      </div>
      <div style="text-align: center; margin-top: 40px;">
        <button class="more-btn" @click="toDetail">了解更多</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Content4",
  methods: {
    toDetail(){
      this.$router.push({path: '/list', query: {id: 6}});
    }
  }
}
</script>

<style scoped>
  .content-4{
    margin: 0 auto;
    max-width: 1200px;
    text-align: center;
  }
  .content-list img{
    width: 100%;
  }

  .more-btn{
    width: 200px;
    margin: 10px;
    border-radius: 20px;
    border: none;
    padding: 10px;
    background: #1563aa;
    color: white;
  }
</style>
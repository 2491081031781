<template>
  <div class="content-5-view" style="padding: 60px 10px;">
    <div class="content-5">
      <div>
        <h1 style="color: #353535;font-size: 36px;">新闻动态</h1>
      </div>
      <div class="content-list">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8" v-for="(item,index) in list" :key="index">
            <div class="list-item" @click="toArticle(item)">
              <img :src="ossUrl + item.photo" />
              <p>{{ item.time }}</p>
              <p>{{ item.title }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="text-align: center; margin-top: 40px;">
        <button class="more-btn" @click="toArticleList">了解更多</button>
      </div>
    </div>
  </div>
</template>

<script>
import {articlePageList} from "@/api/common";
import configs from "@/settings";
export default {
  name: "Content6",
  data(){
    return{
      list: [],
      ossUrl: configs.ossUrl,
    }
  },
  created() {
    this.getArticleList()
  },
  methods: {
    async getArticleList() {
      let result = await articlePageList({"websiteColumnId": 4, "pageSize": 3})
      if (result.code === 1) {
        this.list = result.data.list;
      }
    },
    toArticle(item) {
      if(item.isLink === 1) {
        window.open(item.link)
        return
      }
      this.$router.push({path: '/article', query: {id: item.id}});
    },
    toArticleList(){
      this.$router.push({path: '/list', query: {id: 4}});
    }
  }
}
</script>

<style scoped>
  .content-5{
    margin: 0 auto;
    max-width: 1200px;
    text-align: center;
  }
  .list-item{
    margin-bottom: 20px;
  }
  .list-item img{
    width: 100%;
    height: 231px;
  }
  .list-item p{
    color: #565656;
    margin: 5px;
  }
  .more-btn{
    width: 200px;
    margin: 10px;
    border-radius: 20px;
    border: none;
    padding: 10px;
    background: #1563aa;
    color: white;
  }
</style>
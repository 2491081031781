<template>
  <div class="page">
    <div>
      <el-carousel indicator-position="outside" arrow="never" height="31vw">
        <el-carousel-item v-for="(item, index) in banner" :key="index">
          <img :src="ossUrl + item.img" @click="bannerClick(item)">
        </el-carousel-item>
      </el-carousel>
    </div>
    <Content1 />
    <Content2 />
    <Content3 />
    <Content4 />
    <Content5 />
    <Content6 />
  </div>
</template>

<script>
import Content1 from "./components/Content1";
import Content2 from "./components/Content2";
import Content3 from "./components/Content3";
import Content4 from "./components/Content4";
import Content5 from "./components/Content5";
import Content6 from "./components/Content6";

import {bannerList} from "@/api/common";
import configs from "@/settings";

export default {
  name: "index",
  components: {Content6, Content5, Content4, Content3, Content2, Content1},
  data() {
    return {
      banner: [],
      ossUrl: configs.ossUrl,
    }
  },
  created() {
    this.getBannerList()
  },
  methods: {
    async getBannerList(){
      let result = await bannerList()
      if(result.code === 1){
        this.banner = result.data
      }
    },
    bannerClick(item){
      if(item.type === 1){
        window.open(item.link)
      }
    }
  }
}
</script>

<style scoped>
  .el-carousel__item img {
    width: 100%;
  }
</style>